'use client';

import { useQuery } from '@tanstack/react-query';
import React from 'react';

import classNames from 'classnames';

import classes from './index.module.scss';

interface ShapeProps {
  src: string;
}

const Shape: React.FC<ShapeProps> = (props) => {
  const { src } = props;

  const { data, isError, isLoading } = useQuery({
    queryKey: ['shape', src],
    queryFn: async () =>
      (await fetch(src, { headers: { Accept: '*/*' } })).text(),
    refetchOnWindowFocus: false,
    refetchInterval: Infinity,
    refetchOnMount: false,
    retry: false,
  });

  if (isLoading) {
    return (
      <div className={classes.wrap}>
        <div className={classes.skeleton} />
      </div>
    );
  }

  if (
    !data ||
    !data.trim().startsWith('<svg') ||
    !data.trim().endsWith('</svg>')
  ) {
    return (
      <div className={classNames(classes.wrap)}>
        <div className={classes.label}>
          <div className={classes.text}>no image</div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={classNames(classes.wrap)}>
        <div className={classes.label}>
          <div className={classes.text}>error</div>
        </div>
      </div>
    );
  }

  const fillRegex = /fill="(?!none)[^"]*"/gi;
  const strokeRegex = /stroke="(?!none)[^"]*"/gi;

  return (
    <div className={classNames(classes.wrap)}>
      <div
        className={classNames(classes.icon)}
        dangerouslySetInnerHTML={{
          __html: data
            .trim()
            .replace(fillRegex, 'fill="currentColor"')
            .replace(strokeRegex, 'stroke="currentColor"'),
        }}
      />
    </div>
  );
};

export default Shape;
