'use client';

import React, { useEffect } from 'react';

import { Button, Fade, IconButton, Slide } from '@mui/material';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import {
  CHECKOUT,
  RETURN_POLICY,
  SHIPPING_POLICY,
  WARRANTY,
} from '@/config/routes';
import { CURRENCY } from '@/constants/unit-of-measurement';
import useBodyScrollLock from '@/hooks/use-body-scroll-lock';
import useHeaderClientRect from '@/hooks/use-header-client-rect';
import useShopingCart from '@/hooks/use-shoping-cart';
import CloseIcon from '@/public/icons/x.svg';

import ScrollBar from '../scroll-bar';
import Item from './item';

import classes from './index.module.scss';

const WarningModal = dynamic(() => import('./warning-modal'), { ssr: false });

const ShopingCart: React.FC = () => {
  const { removeItem, open, setOpen, fullList } = useShopingCart();
  const pathname = usePathname();
  const { lockScroll, unlockScroll } = useBodyScrollLock();

  const { bottom } = useHeaderClientRect();

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  useEffect(() => {
    if (open) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [open]);

  const totalPrice = fullList.reduce(
    (acc, item) =>
      acc +
      (item.data.diamond?.totalPrice || 0) +
      (item.data.product?.price || 0),
    0,
  );

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Fade unmountOnExit in={open}>
        <div
          className={classNames(classes.wrap, { [classes.open]: open })}
          style={bottom ? { top: bottom } : undefined}
        >
          <div
            className={classNames(classes.backdrop, { [classes.open]: open })}
            aria-label="backdrop"
            role="button"
            onKeyDown={onClose}
            tabIndex={0}
            onClick={onClose}
          />
          <Slide in={open} direction="left">
            <div className={classes.content}>
              <IconButton onClick={() => onClose()} className={classes.close}>
                <CloseIcon />
              </IconButton>
              <div className={classes.titleWrap}>
                <h2>YOUR CART</h2>
                <div className={classes.subtitle}>
                  Please add three mock rings to your home try on kit to enable
                  checkout.
                </div>
              </div>

              <div className={classes.list}>
                <ScrollBar>
                  {fullList.map((item) => {
                    const productOptions =
                      'product' in item ? item.product.options : undefined;

                    return (
                      <Item
                        key={item.id}
                        product={item.data.product}
                        diamond={item.data.diamond}
                        productOptions={productOptions}
                        onRemove={() => removeItem(item.id)}
                      />
                    );
                  })}
                </ScrollBar>
              </div>

              <div className={classes.bottomSection}>
                <div className={classes.price}>
                  <div className={classes.label}>CART TOTAL</div>
                  <div className={classes.value}>
                    {CURRENCY}
                    {totalPrice.toFixed(2)}
                  </div>
                </div>
                <Button
                  href={CHECKOUT.fullPath}
                  prefetch={false}
                  color="accent"
                  size="large"
                  fullWidth
                  variant="contained"
                >
                  CHECKOUT
                </Button>

                <div className={classes.links}>
                  <div className={classes.item}>
                    <Link
                      className={classes.link}
                      href={SHIPPING_POLICY.fullPath}
                      prefetch={false}
                    >
                      {SHIPPING_POLICY.name}
                    </Link>
                  </div>
                  <div className={classes.item}>
                    <Link
                      className={classes.link}
                      href={RETURN_POLICY.fullPath}
                      prefetch={false}
                    >
                      {RETURN_POLICY.name}
                    </Link>
                  </div>

                  <div className={classes.item}>
                    <Link
                      className={classes.link}
                      href={WARRANTY.fullPath}
                      prefetch={false}
                    >
                      {WARRANTY.name}
                    </Link>
                  </div>

                  <div className={classes.item}>Free Express Shipping</div>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </Fade>
      <WarningModal />
    </>
  );
};

export default ShopingCart;
