'use client';

import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { Button, Container, Grid } from '@mui/material';
import classNames from 'classnames';
import Link from 'next/link';

import Image from '@/app/_components/image';
import { settingsQueryCreator } from '@/store/general';

import classes from './index.module.scss';

interface CategoryBannerProps {
  classes?: {
    wrap?: string;
  };
}

const CategoryBanner: React.FC<CategoryBannerProps> = (props) => {
  const { classes: propsClasses } = props;

  const settings = useQuery(settingsQueryCreator());

  return (
    <section className={classNames(classes.wrap, propsClasses?.wrap)}>
      <Container>
        <div className={classes.row}>
          <Link
            prefetch={false}
            href={settings.data?.shopSettingsUrl || ''}
            className={classes.col}
          >
            <div className={classes.imgWrap}>
              <div className={classes.inner}>
                {settings.data?.shopSettingsImage && (
                  <Image
                    src={settings.data?.shopSettingsImage}
                    fill
                    alt=""
                    className={classes.image}
                  />
                )}
              </div>
            </div>
            <div className={classes.title}>Create Unique Engagement Ring</div>
            <div className={classes.btnWrap}>
              <Grid container justifyContent="center">
                <Grid item md="auto" xs={12}>
                  <Button fullWidth color="bright">
                    SHOP SETTINGS
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Link>
          <Link
            prefetch={false}
            href={settings.data?.shopDiamondsUrl || ''}
            className={classes.col}
          >
            <div className={classes.imgWrap}>
              <div className={classes.inner}>
                {settings.data?.shopDiamondsImage && (
                  <Image
                    src={settings.data?.shopDiamondsImage}
                    fill
                    alt=""
                    className={classes.image}
                  />
                )}
              </div>
            </div>
            <div className={classes.title}>Reveal Your Ideal Diamond</div>
            <div className={classes.btnWrap}>
              <Grid container justifyContent="center">
                <Grid item md="auto" xs={12}>
                  <Button fullWidth color="bright">
                    SHOP diamonds
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default CategoryBanner;
