'use client';

import React, { useState } from 'react';

import { Button, Container, Grid } from '@mui/material';
import classNames from 'classnames';

import { DIAMOND_SEARCH } from '@/config/routes';
import { DiamondAttributeCode, Attribute } from '@/types/diamond';

import List from './list';

import classes from './index.module.scss';

type Props = {
  diamondShapes: Attribute<DiamondAttributeCode.shape>[];
};

const DiamondCategories: React.FC<Props> = (props) => {
  const { diamondShapes } = props;
  const [active, setActive] = useState(1);

  return (
    <section className={classes.wrap}>
      <Container>
        <Grid
          container
          justifyContent="center"
          columnSpacing={4}
          alignItems="center"
        >
          <Grid item md={6} xs={12}>
            <h2>Explore Diamonds</h2>
          </Grid>
          <Grid item md={6} xs={12}>
            <button
              type="button"
              className={classNames(classes.tab, {
                [classes.active]: active === 1,
              })}
              onClick={() => setActive(1)}
            >
              natural diamonds
            </button>
            <button
              type="button"
              className={classNames(classes.tab, {
                [classes.active]: active === 2,
              })}
              onClick={() => setActive(2)}
            >
              lab diamonds
            </button>
          </Grid>
        </Grid>
        <List
          list={diamondShapes.sort(
            (a, b) => (a.priority || 0) - (b.priority || 0),
          )}
        />
        <div className={classes.btnWrap}>
          <Grid container justifyContent="center">
            <Grid md="auto" xs={12}>
              <Button
                fullWidth
                color="accent"
                href={DIAMOND_SEARCH.fullPath}
                prefetch={false}
              >
                view all
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  );
};

export default DiamondCategories;
