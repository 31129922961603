'use client';

import React from 'react';

import dynamic from 'next/dynamic';

import useCheckActivity from '@/hooks/use-check-activity';
import { PromoBox } from '@/types/pages';

const SecondaryBanner = dynamic(() => import('.'), {
  ssr: false,
});

interface Props {
  promoBox: PromoBox | null;
}

const DeferredSecondaryBanner: React.FC<Props> = (props) => {
  const { promoBox } = props;
  const { active } = useCheckActivity();

  if (!active) return null;

  return <SecondaryBanner promoBox={promoBox} />;
};

export default DeferredSecondaryBanner;
