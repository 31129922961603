'use client';

import React from 'react';

import dynamic from 'next/dynamic';

import useCheckActivity from '@/hooks/use-check-activity';

const SubscriptionBanner = dynamic(() => import('.'), {
  ssr: false,
});

const DeferredSubscriptionBanner = () => {
  const { active } = useCheckActivity();

  if (!active) return null;

  return <SubscriptionBanner />;
};

export default DeferredSubscriptionBanner;
