'use client';

import React from 'react';

import dynamic from 'next/dynamic';

import useCheckActivity from '@/hooks/use-check-activity';
import { ProductCategory } from '@/types/product';

const JewelryCategories = dynamic(() => import('../../index'), {
  ssr: false,
});

interface Props {
  list: ProductCategory[];
}

const DeferredJewelryCategories: React.FC<Props> = (props) => {
  const { list } = props;
  const { active } = useCheckActivity();

  if (!active) return null;

  return <JewelryCategories list={list} />;
};

export default DeferredJewelryCategories;
