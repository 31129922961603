'use client';

import React, { useState } from 'react';

import dynamic from 'next/dynamic';
import { Swiper as ISwiper } from 'swiper/types';

import NavigationButton from '@/app/_components/swiper/navigation-button';
import useCheckActivity from '@/hooks/use-check-activity';
import { DiamondAttributeCode, Attribute } from '@/types/diamond';

import Placeholder from './placeholder';

import classes from './index.module.scss';

const CategoriesSlider = dynamic(() => import('./categories-slider'), {
  ssr: false,
});

interface Props {
  list: Attribute<DiamondAttributeCode.shape>[];
}

const List: React.FC<Props> = (props) => {
  const { list } = props;
  const [swiper, setSwiper] = useState<ISwiper | null>(null);
  const { active } = useCheckActivity();

  const inner = (() =>
    !active ? (
      <Placeholder list={list} />
    ) : (
      <CategoriesSlider list={list} setSwiper={setSwiper} />
    ))();

  return (
    <div className={classes.sliderWrap}>
      {swiper && (
        <NavigationButton
          direction="prev"
          swiper={swiper}
          className={classes.prev}
        />
      )}
      <div className={classes.slider}>{inner}</div>
      {swiper && (
        <NavigationButton
          direction="next"
          swiper={swiper}
          className={classes.next}
        />
      )}
    </div>
  );
};

export default List;
