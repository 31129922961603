import { JEWELRY, MATCHING_JEWELRES } from '@/config/routes';

import { objToProductSlug } from './search-params';

export const getSlug = (str: string) =>
  str
    .toLowerCase()
    .replace(/[().,/*%$#@!?><;:'"|]/g, '')
    .replaceAll(' ', '-');

export const splitSelfDomain = (link: string) => {
  const domain = process.env.NEXT_PUBLIC_DOMAIN || '';
  const regex = new RegExp(`^https?://${domain}`);

  if (regex.test(link)) {
    return link.replace(regex, '');
  }
  return link;
};

export const getVariantSlug = (data: {
  shape?: string | null;
  metal: string;
  variantId: number;
}) => {
  const slug: string[] = [];
  if (data.shape) {
    slug.push(data.shape);
  }
  slug.push(data.metal, data.variantId.toString());

  return slug.map((item) => getSlug(item)).join('-');
};

export const getVariantSlugForMatching = (data: {
  shape?: string | null;
  metal: string;
  variantId: number;
  options?: Record<string, string | number>;
}) => {
  const { shape, metal, variantId, options } = data;

  const objSlug = {
    ...(shape ? { shape } : {}),
    metal,
    id: variantId,
    ...options,
  };

  return objToProductSlug(objSlug);
};

export const getVariantUrl = (
  data: {
    productSlug: string;
    shape?: string | null;
    metal: string;
    variantId: number;
    categorySlug: string;
    options?: Record<string, string | number>;
  },
  jewelryBuilder?: { diamondId: number },
  slugForMatching?: boolean,
) => {
  let slug = '';
  const path = jewelryBuilder
    ? [MATCHING_JEWELRES.fullPath, jewelryBuilder.diamondId]
    : [JEWELRY.fullPath, data.categorySlug || 'category', data.productSlug];

  if (slugForMatching) {
    slug = getVariantSlugForMatching(data);
  } else {
    slug = getVariantSlug(data);
  }

  path.push(slug);

  return path.join('/');
};
