import React from 'react';

import Link from 'next/link';

import Image from '@/app/_components/image';
import Shape from '@/app/_components/shape';

import classes from './list-item.module.scss';

interface Props {
  href: string;
  schematicImageUrl: string | null;
  imageUrl: string | null;
  itemTitle: string | null;
}

const ListItem: React.FC<Props> = (props) => {
  const { href, schematicImageUrl, imageUrl, itemTitle } = props;
  return (
    <Link href={href} className={classes.slide} prefetch={false}>
      <div className={classes.imgWrap}>
        <Shape src={schematicImageUrl || ''} />
        {imageUrl && <Image alt="diamond" src={imageUrl} fill />}
      </div>
      <span className={classes.name}>{itemTitle}</span>
    </Link>
  );
};

export default ListItem;
