'use client';

import React from 'react';

import dynamic from 'next/dynamic';

import useCheckActivity from '@/hooks/use-check-activity';

const AppExtras = dynamic(() => import('./app-extras'), {
  ssr: false,
});

const DynamicClientSideComponents = () => {
  const { active } = useCheckActivity();

  if (!active) return null;

  return <AppExtras />;
};

export default DynamicClientSideComponents;
