'use client';

import React from 'react';

import dynamic from 'next/dynamic';

import useCheckActivity from '@/hooks/use-check-activity';
import { ListProduct } from '@/types/product';

const ProductSlider = dynamic(() => import('..'), {
  ssr: false,
});

interface ProductSliderProps {
  data: ListProduct[];
  title: string;
  classes?: {
    itemLink?: string;
    slider?: string;
    slide?: string;
    wrap?: string;
  };
  paginationColor?: 'primary' | 'inherit';
}

const DeferredProductSlider: React.FC<ProductSliderProps> = (props) => {
  const { data, title, classes: styles, paginationColor } = props;
  const { active } = useCheckActivity();

  if (!active) return null;

  return (
    <ProductSlider
      data={data}
      title={title}
      classes={styles}
      paginationColor={paginationColor}
    />
  );
};

export default DeferredProductSlider;
