import React from 'react';

import { DIAMONDS } from '@/config/routes';
import { Attribute, DiamondAttributeCode } from '@/types/diamond';

import ListItem from './list-item';

import classes from './placeholder.module.scss';

interface Props {
  list: Attribute<DiamondAttributeCode.shape>[];
}

const Placeholder: React.FC<Props> = (props) => {
  const { list } = props;

  return (
    <div className={classes.list}>
      {list.map((item) => (
        <div key={item.id} className={classes.item}>
          <ListItem
            href={`${DIAMONDS.fullPath}?shape=${item.id}`}
            schematicImageUrl={item.schematicImageUrl}
            imageUrl={item.imageUrl}
            itemTitle={item.title}
          />
        </div>
      ))}
    </div>
  );
};

export default Placeholder;
