export const getSingleParam = (param: string | string[]) =>
  typeof param === 'string' ? param : param[0] || '';

export const getMultipleParams = (param: string | string[]) =>
  typeof param === 'string' ? [param] : param;

export const objToProductSlug = (
  data: Record<string, string | number>,
): string =>
  Object.entries(data)
    .map(
      ([key, value]) =>
        `${key.toLowerCase()}=${value.toString().toLocaleLowerCase().split(' ').join('_')}`,
    )
    .join('-');

export const parseStringToObject = (
  input: string,
): Record<string, string | number> => {
  const pairs = input.split('-');
  const result: Record<string, string | number> = {};

  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    if (key && value) {
      const transformedValue = Number(value) || value.split('_').join(' ');
      result[key.split('_').join(' ')] = transformedValue;
    }
  });

  return result;
};
