import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/dynamic-client-side-components/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/footer/menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/header/_components/main-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/header/_components/menu-icons.tsx");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/image/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/progress/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/shoping-cart/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/libs/swiper/theme.css");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/footer/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/header/index.module.scss");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/app/_components/header/heder-loader.module.scss");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/next/font/local/target.css?{\"path\":\"config/theme/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./gotham_book.woff2\",\"weight\":\"400\"},{\"path\":\"./gotham_medium.woff2\",\"weight\":\"500\"}],\"display\":\"swap\"}],\"variableName\":\"gotham\"}");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/node_modules/next/font/local/target.css?{\"path\":\"config/theme/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./CarlaSansRegular.woff2\",\"weight\":\"400\"}],\"display\":\"swap\"}],\"variableName\":\"carlaSans\"}");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/public/icons/diamond.svg");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/public/images/footer-logo.png");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/public/images/logo-small.png");
;
import(/* webpackMode: "eager" */ "/home/tgjewellers-front/www/tgjewellers.com/styles/style.scss");
