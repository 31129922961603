import { useEffect, useState } from 'react';

const useCheckActivity = () => {
  const [active, setActive] = useState(false);
  const onMousemove = () => {
    setActive(true);
  };

  const onScroll = () => {
    setActive(true);
  };

  const onTouchstart = () => {
    setActive(true);
  };

  useEffect(() => {
    if (!active) {
      window.addEventListener('mousemove', onMousemove, {
        once: true,
      });
      window.addEventListener('scroll', onScroll, {
        once: true,
      });
      window.addEventListener('touchstart', onTouchstart, {
        once: true,
      });
    }

    return () => {
      window.removeEventListener('mousemove', onMousemove);
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('touchstart', onTouchstart);
    };
  }, []);

  return { active };
};

export default useCheckActivity;
